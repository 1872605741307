import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import iconReview from "../../assets/images/icon-review.svg";
import icon2 from "../../assets/images/menu-icon-2.svg";
import IntegrationIcon from "../../assets/images/menu-icon-3.svg";
import icon4 from "../../assets/images/menu-icon-4.svg";
import icon5 from "../../assets/images/icon-setting.svg";
import reportIcon from "../../assets/images/reportIcon.svg";
import overViewIcon from "../../assets/images/icon-review.svg";
import iconSubscription from "../../assets/images/icon-subscription.svg";
import customerSupport from "../../assets/images/help.svg";
import vault from "../../assets/images/vault/Docklock.svg";
import "./verticalMenu.scss"
import {
  Dropdown,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";
import ComplianceActivityFilterBlade from "../../components/Reports/ComplianceActivityReport/CompliaceActivityFilterBlade";
import FlaggingReportFilterBlade from "../Reports/FlaggingReport/FlaggingReportFilterBlade";
import { useSelector } from "react-redux";


const VerticalMenu = () => {
  const role = useSelector((state: any) => state.uR.role);
  const uat = useSelector((state: any) => window?.atob(state.uat.uat));
  const aiM = useSelector((state: any) => state.aiM.aiM);
  // Complience Active  blade
  const [showComplianceActivity, setShowComplianceActivity] = useState(false);
  const handleCloseComplianceActivity = () => setShowComplianceActivity(false);
  const handleShowComplianceActivity = () => setShowComplianceActivity(true);

  // Complience Report  blade
  const [showFlagginReport, setShowFlagginReport] = useState(false);
  const handleCloseFlagginReport = () => setShowFlagginReport(false);
  const handleShowFlagginReport = () => setShowFlagginReport(true);
  const [complainceUserEditUser, setComplainceUserEditUser] = useState(true);
  const [complainceUserEditTrigger, setComplainceUserEditTrigger] = useState(true);
  const [showCustSupport, setShowCustSupport] = useState(false);


  useEffect(() => {
    var data1 = atob(localStorage.getItem('ucd') ?? "");
    if (role === "COMPLIANCE_OFFICER") {
      if ((data1 === "" || data1 === undefined)) {
      } else {
        var json = JSON.parse(data1);
        setComplainceUserEditUser(json.addEditUser);
        setComplainceUserEditTrigger(json.addEditTrigers);
      }
    }
  }, []);

  const handleIframeLoad = () => {
    const iframe: any = document.getElementById('iframe');
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    if (iframeDocument.readyState === 'complete') {
      const containerElement = iframeDocument.querySelector('.container');
      if (containerElement) {
        containerElement.classList.remove('container');
      }
    }
  }

  return (
    <>
      <div id="vertical_menu" className="verticle-menu">
        <div className="menu-list">
          {role === "ACCOUNT_ADMIN" ||
            role === "COMPLIANCE_OFFICER" ||
            role === "BASIC_USER" ? (
            <OverlayTrigger
              key="right1"
              placement="right"
              overlay={<Tooltip id="overview">{"Overview"}</Tooltip>}
            >
              <Link id="t-1" to={"/dashboard"} className="menu-item">
                {" "}
                <img src={overViewIcon} alt="icon" className="menu-icon" />
              </Link>
            </OverlayTrigger>
          ) : (
            ""
          )}
          {complainceUserEditTrigger
            && role !== "BASIC_USER"
            ? (
              <OverlayTrigger
                key="right2"
                placement="right"
                overlay={<Tooltip id="Flagging">Flagging</Tooltip>}
              >
                <Link id="t-1" to={"/flagging"} className="menu-item">
                  {" "}
                  <img src={icon2} alt="icon" className="menu-icon" />
                </Link>
              </OverlayTrigger>
            ) : (
              ""
            )}
          {complainceUserEditUser
            && role !== "BASIC_USER"
            ? (
              <OverlayTrigger
                key="right3"
                placement="right"
                overlay={<Tooltip id="Organization">Organization</Tooltip>}
              >
                <Link id="t-1" to={"/organization"} className="menu-item">
                  {" "}
                  <img src={icon4} alt="icon" className="menu-icon" />
                </Link>
              </OverlayTrigger>
            ) : (
              ""
            )}

          {role === "ACCOUNT_ADMIN" ||
            role === "COMPLIANCE_OFFICER" ? (
            <OverlayTrigger
              key="right4"
              placement="right"
              overlay={<Tooltip id="Connectors">Connectors</Tooltip>}
            >
              <Link id="t-1" to={"/connector"} className="menu-item">
                <img src={IntegrationIcon} alt="icon" className="menu-icon" />
              </Link>
            </OverlayTrigger>
          ) : (
            ""
          )}

          {role === "ACCOUNT_ADMIN" ||
            role === "COMPLIANCE_OFFICER" ? (

            <>
              <OverlayTrigger
                key="right4"
                placement="top"
                overlay={<Tooltip id="Reports">Reports</Tooltip>}
              >
                <Dropdown drop="end">
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <img src={reportIcon} alt="icon" className="menu-icon" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleShowComplianceActivity}>
                      Compliance Activity Report
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleShowFlagginReport}>
                      Compliance Flagging Report
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </OverlayTrigger>

              {/* {(role === "ACCOUNT_ADMIN" ||
                role === "COMPLIANCE_OFFICER") && (
                  <OverlayTrigger
                    key="right4"
                    placement="right"
                    overlay={<Tooltip id="Vault">Vault</Tooltip>}
                  >
                    <Link id="t-1" to={"/vault"} className="menu-item">
                      <img src={vault} alt="icon" className="menu-icon" />
                    </Link>
                  </OverlayTrigger>
                )} */}


            </>
          ) : (
            ""
          )}

          <OverlayTrigger
            key="right5"
            placement="right"
            overlay={<Tooltip id="Settings">Settings</Tooltip>}
          >
            <Link id="t-1" to={"/setting"} className="menu-item">
              <img src={icon5} alt="icon" className="menu-icon" />
            </Link>
          </OverlayTrigger>



          {uat ===
            "ACCOUNT_ADMIN" ? (
            <OverlayTrigger
              key="right6"
              placement="right"
              overlay={<Tooltip id="Billing">Billing</Tooltip>}
            >
              <Link id="t-1" to={"/billing"} className="menu-item">
                {" "}
                <img src={iconSubscription} alt="icon" className="menu-icon" />
              </Link>
            </OverlayTrigger>
          ) : (
            ""
          )}

          {role === "BASIC_USER" ? (
            <div className="menu-list">
              <OverlayTrigger
                key="right7"
                placement="right"
                overlay={<Tooltip id="Integration">Connectors</Tooltip>}
              >
                <Link id="t-1" to={"/connector"} className="menu-item">
                  {" "}
                  <img src={IntegrationIcon} alt="icon" className="menu-icon" />
                </Link>
              </OverlayTrigger>
            </div>
          ) : (
            ""
          )}

          <OverlayTrigger
            key="right6"
            placement="right"
            overlay={<Tooltip id="Billing">Customer Support</Tooltip>}
          >
            <span id="t-1" className="menu-item cursor-pointer" onClick={() => setShowCustSupport(true)}>
              <img src={customerSupport} alt="icon" className="menu-icon" />
            </span>
          </OverlayTrigger>

        </div>
        <div className="version-badge">
          V-2.0.4
        </div>
      </div>


      {/* Compliance Activity By User */}
      <Offcanvas
        show={showComplianceActivity}
        onHide={handleCloseComplianceActivity}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Compliance Activity Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="form-style">
          <ComplianceActivityFilterBlade
            closeBlade={() => {
              handleCloseComplianceActivity();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Compliance Flagging Report */}
      <Offcanvas
        show={showFlagginReport}
        onHide={handleCloseFlagginReport}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Compliance Flagging Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="form-style">
          <FlaggingReportFilterBlade
            closeBlade={() => {
              handleCloseFlagginReport();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showCustSupport}
        onHide={() => setShowCustSupport(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Customer Support</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="form-style">
          <iframe
            id="iframe"
            src="https://share.hsforms.com/1shPYJXyRTbyY5TOLEe0B7wqp8hl"
            title="Archive Intel"
            height={1200}
            className="test"
          >
          </iframe>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default VerticalMenu;







