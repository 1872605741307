import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProgressBar } from "../Redux/actions";

const FTP_BASE_URL = "https://ftp.archiveintel.com/api/";
//Live
const BASE_URL = "https://api.archiveintel.com/client/";
// const BASE_URL = "http://192.168.2.14:8082/client/";
//UAT
// const BASE_URL = "https://uat-clientapi.archiveintel.com/client/";

interface PropData {
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  key?: string;
  dispatch?: any;
  _fileArray?: any;
  access_token?: string;
  t_id?: string;
  signal?: any;
}

const WebService = {

  /*------------------------------------------------
                     BRFORE LOGIN SERVICE
   -------------------------------------------------*/
  beforeLoginAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (props.isShowError) {
            reject(this.errorHandler(error))
          } else {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            reject(error)
          };
        });
    });
  },

  beforeLoginPutAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .put(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (props.isShowError) {
            reject(this.errorHandler(error))
          } else {
            toast.error("Invalid details");
            reject(error)
          };
        });
    });
  },

  beforeLoginGetAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const queryParams = new URLSearchParams();
      for (let key in props.body) {
        queryParams.append(key, props.body[key]);
      }
      const url = `${BASE_URL}${props.action}${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .get(url, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (props.isShowError) {
            reject(this.errorHandler(error))
          } else {
            toast.error("Invalid details");
            reject(error)
          };
        });
    });
  },
  postAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        // Authorization: "Bearer " + localStorage.getItem("user_access_token"),
        Authorization: "Bearer " + props.access_token,
        "X-tenant-id": props.t_id,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },


  getAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers: any = {
        "Content-Type": "application/x-www-form-urlencoded",
        // Authorization: "Bearer " + localStorage.getItem("user_access_token"),
        Authorization: "Bearer " + props.access_token,
      };
      if (props.t_id !== null && props.t_id !== undefined && props.t_id !== "" && props.t_id !== "undefined") {
        headers["X-tenant-id"] = props.t_id;
      }
      axios
        .get(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  putAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        "X-tenant-id": props.t_id,
      };
      var ad: any = {
        headers: headers,
      };
      if (props.signal) {
        ad = {
          headers: headers,
          signal: props.signal, // Add signal for aborting
        };
      }
      axios
        .put(`${BASE_URL}${props.action}`, props.body, ad)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }
          if (props.isShowError) {
            reject(this.errorHandler(error));
          } else {
            reject(error);
          }
        });
    });
  },


  deleteAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        // Authorization: "Bearer " + localStorage.getItem("user_access_token"),
        Authorization: "Bearer " + props.access_token,
        "X-tenant-id": props.t_id,
      };
      axios
        .delete(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },



  uploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }
      const headers = {
        // Authorization: "Bearer " + localStorage.getItem("user_access_token"),
        Authorization: "Bearer " + props.access_token,
        "X-tenant-id": props.t_id,
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },



  multiUploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (Array.isArray(props._fileArray) && props.key) {
      props._fileArray.forEach((file: any, index: any) => {
        formData.append(`${props.key}`, file);
      });
    } else if (props._file && props.key) {
      formData.append(props.key, props._file);
    }

    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }
      const headers = {
        // Authorization: "Bearer " + localStorage.getItem("user_access_token"),
        Authorization: "Bearer " + props.access_token,
        "X-tenant-id": props.t_id,
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },




  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage = "Unauthorized";
      this.logout();
    } else if (error.status === 404) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else {
      errorMessage = error.data.message;
    }

    toast.error(errorMessage);
    return errorMessage;
  },

  addLoader(id?: any, status?: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button) {
        button.disabled = true;
        var loader = document.createElement("span");
        loader.innerHTML =
          '<span style="margin-right:5px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span className="visually-hidden"></span>';
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  },



  /*------------------------------------------------
                    FTP SERVICE
  -------------------------------------------------*/

  ftpPostAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }

      const headers = {
        "X-Requested-With": "XmlHttpRequest",
        "Accept": "application/json, text/plain, */*"
      };

      axios
        .post(
          `${FTP_BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
            withCredentials: true
          },

        )
        .then((response: any) => {
          resolve(response.headers['set-cookie']);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },
  ftpUploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }

      const axiosConfig: AxiosRequestConfig = {
        withCredentials: true,
        onUploadProgress: (progressEvent: any) => {
          props.dispatch(updateProgressBar((progressEvent.progress * 100).toFixed(2)));
        }
      };
      axios
        .post(`${FTP_BASE_URL}${props.action}`, props._file, axiosConfig
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

};

export default WebService;
